<template>
    <v-skeleton-loader
        v-if="loading"
        type="card"
    ></v-skeleton-loader>
    <v-card v-else>
        <v-card-title style="background: #0085FF; color: white;">
            {{ trans('fields.common.landings') }}
        </v-card-title>
        <v-card-text v-if="!loading">
            <v-simple-table v-if="landings && landings.length">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>{{ trans('fields.common.landing') }}</th>
                    <th>{{ trans('fields.common.offers') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="landing in landings" :key="landing.id">
                    <td>{{ landing.id }}</td>
                    <td>{{ landing.label }}</td>
                    <td>{{ landing.num_offers }}</td>
                </tr>
                </tbody>
            </v-simple-table>
            <template v-else>
                <v-row>
                    <v-col>
                        <h4>{{ trans('pages.dashboard.landingsHeader') }}</h4>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-dialog
                            v-model="dialog"
                            max-width="1000"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-img
                                    v-if="$store.getters.curlocale == 'ru'"
                                    src="@/assets/images/dashboard/landings_ru.png"
                                    max-width="100%"
                                    v-bind="attrs"
                                >
                                    <div class="youtube_btn" v-on="on"></div>
                                </v-img>
                                <v-img
                                    v-else
                                    src="@/assets/images/dashboard/landings_en.png"
                                    max-width="100%"
                                    v-bind="attrs"
                                >
                                    <div class="youtube_btn" v-on="on"></div>
                                </v-img>
                            </template>

                            <v-card v-if="dialog">
                                <div
                                    v-if="$store.getters.curlocale == 'ru'"
                                    class="embed-container"
                                >
                                    <iframe v-if="$store.getters.vendorAlias === 'workle'" width="100%" height="600" src="https://www.youtube.com/embed/mxMek5Cj8bE?autoplay=1&rel=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    <iframe v-else width="100%" height="600" src="https://www.youtube.com/embed/UYR6Lz6ezeY?autoplay=1&rel=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                                <div
                                    v-else
                                    class="embed-container"
                                >
                                    <iframe width="100%" height="600" src="https://www.youtube.com/embed/GuoRmhoN8eE?autoplay=1&rel=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col v-if="$store.getters.vendorAlias === 'workle'" v-html="trans('pages.dashboard.landingsListItemsWorkle')"></v-col>
                    <v-col v-else v-html="trans('pages.dashboard.landingsListItems')"></v-col>
                </v-row>
            </template>
            <div class="text-center">
                <v-btn
                    small
                    color="primary"
                    outlined
                    class="mt-4"
                    :to="{name: 'user.landings'}"
                >
                    {{ trans('buttons.landing.newItem') }}
                    <v-icon right small>mdi mdi-plus</v-icon>
                </v-btn>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "landings",
        data() {
            return {
                landings: null,
                loading: true,
                dialog: false,
            }
        },
        created() {
            this.load();
        },
        methods: {
            load(){
                axios.get(this.route('user.dashboard.landings')).then( response => {
                    this.landings = response.data;
                }).catch( err => {
                    console.log(err);
                }).then( () => {
                    this.loading = false;
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    .youtube_btn{
        cursor: pointer;
        position: absolute;
        width: 100px;
        height: 70px;
        top: 50%;
        margin-top: -35px;
        left: 50%;
        margin-left: -50px;
        background: url("../../../assets/images/dashboard/youtube_btn.png") no-repeat top left;
        background-size: contain;
    }
    .youtube_btn:hover {
        background: url("../../../assets/images/dashboard/youtube_btn_active.png") no-repeat top left;
    }
    .embed-container {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        max-width: 100%;
    }
    .embed-container iframe, .embed-container object, .embed-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
</style>
