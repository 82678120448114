<template>
    <v-row v-if="$store.getters.vendorId">
        <v-col cols="12" md="6">
            <landings></landings>
        </v-col>
        <v-col cols="12" md="6">
            <domains></domains>
        </v-col>
    </v-row>
    <v-row v-else>
        <v-col cols="12" md="6" lg="4">
            <landings></landings>
        </v-col>
        <v-col cols="12" md="6" lg="4">
            <links></links>
        </v-col>
        <v-col cols="12" md="6" lg="4">
            <domains></domains>
        </v-col>
    </v-row>
</template>

<script>
    import Landings from "@/components/user/dashboard/landings";
    import Links from "@/components/user/dashboard/links";
    import Domains from "@/components/user/dashboard/domains";
    export default {
        name: "Dashboard",
        components: {Domains, Links, Landings}
    }
</script>

<style scoped>

</style>
